var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"eruda-performance-timing\">\n        <div class=\"eruda-inner-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":8,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPerformanceDetail") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"eruda-bar\">\n                    <span style=\"position:relative;left:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"start") : depth0), depth0))
    + "%;width:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"len") : depth0), depth0))
    + "%\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"duration") : depth0), depth0))
    + "ms)</span>\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"eruda-performance-timing-data\">\n            <table>\n                <thead>\n                    <tr>\n                        <th>Name</th>\n                        <th>Time(ms)</th>\n                    </tr>\n                </thead>\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"timing") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":27,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\n                            <td>"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "</td>\n                            <td>"
    + alias2(alias1(depth0, depth0))
    + "</td>\n                        </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"eruda-title\">\n        ResourceTiming\n        <div class=\"eruda-btn eruda-refresh-resource-timing\">\n            <span class=\"eruda-icon-refresh\"></span>\n        </div>\n    </div>\n    <div class=\"eruda-entries\">\n        <table>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"entries") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":58,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr class=\"eruda-entry\" data-idx=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\">\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n                        <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"initiatorType") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":47,"column":86}}})) != null ? stack1 : "")
    + "</td>\n                        <td class=\"eruda-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayTime") : depth0), depth0))
    + "</td>\n                        <td class=\"eruda-blue\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "</td>\n                    </tr>\n                    <tr>\n                        <td class=\"eruda-timeline\" colspan=\"4\">\n                            <span style=\"border-left: 1px solid #707d8b; background: #707d8b; margin-left: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"timeline") : depth0)) != null ? lookupProperty(stack1,"left") : stack1), depth0))
    + "%; width: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"timeline") : depth0)) != null ? lookupProperty(stack1,"connection") : stack1), depth0))
    + "%;\"></span>\n                            <span style=\"background: #009688; width: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"timeline") : depth0)) != null ? lookupProperty(stack1,"ttfb") : stack1), depth0))
    + "%\"></span>\n                            <span style=\"background: #2196f3; width: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"timeline") : depth0)) != null ? lookupProperty(stack1,"response") : stack1), depth0))
    + "%\"></span>\n                        </td>\n                    </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"initiatorType") : depth0), depth0))
    + "</span>";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"eruda-not-supported\">\n        Not supported for this browser!\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"timing") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":32,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"entries") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":0},"end":{"line":62,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notSupported") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":0},"end":{"line":68,"column":7}}})) != null ? stack1 : "");
},"useData":true});